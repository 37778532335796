<template>
  <div>
    <div style='margin-bottom: 8px;border-bottom: #f2f2f2 1px solid;padding: 10px'>
      <div class='flex-sb'>
        <div class='top_box'>
          <p class='top_box__title'>刊例提报报价信息</p>
        </div>
        <div>
          <el-link type='primary' @click='reset'
                   icon='el-icon-refresh' title='清空全部（当月和预估）报价信息，使用配置的报价数据结构'>重新录入
          </el-link>
          <el-link :disabled='!formData.platform_code' type='warning' icon='el-icon-document-copy'
                   @click='copyLastPlatformQuote'
                   title='【预估】报价采用上一次的【终价】'
                   style='margin-left: 20px'>
            不调价
          </el-link>
          <!--       -->
        </div>
        <div class='box-option'>
          <div v-if="!!formData.id&&userPermissions.indexOf('plc_edit_price')>-1">
            <el-link style='padding: 3px 0' type='warning' @click='editPrice = true'
                     v-if='!editPrice'>
              <i class='el-icon-edit-outline'></i>
              {{ formData.status === 2 ? '申请改价' : '修改' }}
            </el-link>
            <el-link style='padding: 3px 0' type='primary' @click='saveEdit' v-else>
              <i class='el-icon-finished'></i>保存
            </el-link>
          </div>
        </div>

      </div>
    </div>
    <div style='padding: 5px'>
      <div>
        <div style='margin-bottom: 8px' v-if='priceTypeMap.showLast'>
          <!--          当月-->
          <AdjustPricePanel
            :key='`currAdjustPricePanel_${formData.id||0}`'
            ref='currAdjustPricePanel'
            v-if='formData.current_quote'
            :show-multi-price='priceTypeMap.multi'
            :show-single-price='priceTypeMap.single'
            :show-mixed-price='priceTypeMap.mixed'
            :quote-info.sync='formData.current_quote'
            :title='`当月（${currentMonth}）价格`'
            :edit-price.sync='editPrice'
            :platform-type='platformType' />
        </div>
        <div>
          <!--          下月预估-->
          <AdjustPricePanel
            :key='`nextAdjustPricePanel_${formData.id||0}`'
            ref='nextAdjustPricePanel'
            v-if='formData.next_quote'
            :show-multi-price='priceTypeMap.multi'
            :show-single-price='priceTypeMap.single'
            :show-mixed-price='priceTypeMap.mixed'
            :quote-info.sync='formData.next_quote'
            :title='`下月（${year}年${month}月）预估`'
            :edit-price.sync='editPrice'
            :platform-type='platformType' />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import AdjustPricePanel from '@/pages/publication/adjust/AdjustPricePanel'
import plcMap from '@/json/plc.json'
import {deepClone} from '@/utils'
import ApeEditor from '@/components/ApeEditor'
// 报价信息-数据结构
const quoteStruct = plcMap.form.quoteStruct

export default {
  name: 'QuotePrice',
  components: { AdjustPricePanel, ApeEditor },
  computed: {
    ...mapGetters(['userPermissions']),
    currentMonth() {
      let date = new Date(this.year, this.month - 1)
      date.setMonth(date.getMonth() - 1)
      return date.getFullYear() + '年' + (date.getMonth() + 1) + '月'
    },
    //报价方式：品类报价（1：n）；类目报价
    priceTypeMap() {
      let customPriceMap = plcMap.customPriceMap
      return customPriceMap[this.platformType] || {
        'showLast': true,
        'multi': true,
        'single': true,
        'mixed': true
      }
    },
    year() {
      return this.formData.year
    },
    month() {
      return this.formData.month
    }
  },
  props: {
    formData: {
      type: Object
    },
    platformType: {
      type: String
    }
  },
  data() {
    return {
      showPriceBox: false,
      editPrice: true,//修改报价
      toolbar: 'undo redo paste',
      //配置信息
      setting: {}
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async show(setting, formData) {
      this.setting = { ...setting }
      this.formData.year = formData.year
      this.formData.month = formData.month
      if (formData.id) {
        this.formData.id = formData.id
        this.formData.current_quote = JSON.parse(JSON.stringify(formData.current_quote))
        this.formData.next_quote = JSON.parse(JSON.stringify(formData.next_quote))
      } else {
        delete this.formData.id
        await this.calcPlatformQuote()
      }
      this.editPrice = true
    },
    showMultiFirst() {
      this.$nextTick(() => {
        setTimeout(() => {
          //初始化-渲染默认（富文本、报价）
          if (this.$refs['currAdjustPricePanel'])
            this.$refs['currAdjustPricePanel'].addFirstMultiRow()
          if (this.$refs['nextAdjustPricePanel'])
            this.$refs['nextAdjustPricePanel'].addFirstMultiRow()
        }, 500)
      })


    },
    close() {
      this.editPric = false
    },
    reset() {
      this.calcPlatformQuote()
    },
    calcPlatformQuote() {
      this.formData.current_quote = JSON.parse(JSON.stringify(quoteStruct))
      this.formData.next_quote = JSON.parse(JSON.stringify(quoteStruct))
      if (this.priceTypeMap.single) {
        // 视频时长报价，根据配置显示
        let singleOptions = this.setting.setting.quote.setting.single
        if (singleOptions) {
          this.formData.current_quote.single = JSON.parse(JSON.stringify(singleOptions))
          this.formData.next_quote.single = JSON.parse(JSON.stringify(singleOptions))
        }
      }
    },

    /**
     * 同步上月定价：不调价
     */
    copyLastPlatformQuote() {
      // handleSyncLastMonth
      // 本月 = 上月（当月）
      this.formData.next_quote = deepClone(this.formData.current_quote)
    },
    // clearQuote() {
    //   this.$emit('clearQuote')
    // },
    handleLSFCRichTxt(val, field) {

      if (this.formData.current_quote)
        this.formData.current_quote.mixed[field]['val'] = val

      this.$emit('change')
    },
    //修改保存-刊例报价
    async saveEdit() {
      this.editPrice = false
      //修改报价
      let data = {
        id: this.formData.id,
        current_quote: this.formData.current_quote,
        next_quote: this.formData.next_quote
      }
      let info = await this.$api.saveQuoteItem(data)
      if (info)
        this.msgSuccess('保存成功')

      this.$emit('updated')
    },
    handleChange(field, val) {
      this.$set(this.formData, field, val)
    },
    getCurrentForm() {
      return { next_quote: this.formData.next_quote, current_quote: this.formData.current_quote }
    }
  }
}
</script>

<style lang='scss' scoped>

.top_box {
  background: #ffffff;
  border-radius: 20px;

  &__title {
    color: black;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    padding-left: 20px;
  }

  &__title::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    width: 8px;
    height: 8px;
    border: 4px solid #3590CC;
    background-color: white;
    border-radius: 50%;
  }
}

.box-option {
  line-height: 20px;
  width: 60px;
  height: 20px;
  padding: 10px;
  text-align: center;
}

</style>
