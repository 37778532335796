<template>
  <div class='box-quote'>
    <!--    参考报价-->
    <div class='choice-quote'>
      <div class='price-box'>
        <div class='flex-sb'>
          <span class='opt-title'>当月价格-报价明细</span>
          <el-button type='primary' :plain="!(formData.quote_source==='current_quote')"
                     icon='el-icon-circle-check'
                     @click="selectQuote(formData.current_quote,'current_quote')">
            {{ (formData.quote_source === 'current_quote') ? '已选择' : '未选择' }}
          </el-button>
        </div>
        <div>
          <PriceStructView :key='`current_pg_${formData.id}`' style='width: 100%' v-if='formData.current_quote'
                           :quote-obj='formData.current_quote'
                           :platform-type='platformType' />
        </div>
      </div>

      <div class='price-box'>
        <div class='flex-sb'>
          <span class='opt-title'>下月估价-报价明细</span>
          <el-button type='primary' :plain="!(formData.quote_source==='next_quote')" icon='el-icon-circle-check'
                     class='check-btn'
                     @click="selectQuote(formData.next_quote,'next_quote')">
            {{ (formData.quote_source === 'next_quote') ? '已选择' : '未选择' }}

          </el-button>
        </div>
        <div>
          <PriceStructView :key='`next_pg_${formData.id}`' style='width: 100%'
                           v-if='formData.next_quote'
                           :quote-obj='formData.next_quote'
                           :platform-type='platformType' />
        </div>
      </div>
    </div>

    <!--      商业化营销中心终价-报价明细-->
    <div class='mb8 panel-border'>
      <AdjustPricePanel
        :key='`real_pg_${formData.id}`'
        :show-reset='true'
        ref='refRealQuotePanel'
        @handleReset='handleReset'
        :quote-info.sync='formData.real_quote'
        :show-single-price='priceTypeMap.single'
        :show-multi-price='priceTypeMap.multi'
        :show-mixed-price='priceTypeMap.mixed'
        :platform-type='platformType'
        :title='`商业化营销中心终价`'
        :edit-price='true' />
    </div>

    <div style='text-align: center'>
      <el-button type='primary' size='medium' @click='saveQuote' :disabled='loadingSb' :loading='loadingSb'
                 v-if="userPermissions.indexOf('plc_save_quote')>-1">
        保存
      </el-button>
      <el-button @click='notifyByDing' size='medium' v-if="userPermissions.indexOf('plc_save_quote')>-1"
                 :disabled='loadingNotify' :loading='loadingNotify'>
        通知定价
      </el-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import PriceGrids from '@/pages/publication/compoents/PriceGrids'
import AdjustPricePanel from '@/pages/publication/adjust/AdjustPricePanel'
import plcMap from '@/json/plc.json'
import PriceStructView from '@/pages/publication/compoents/PriceStructView'
import {html2json} from 'html2json'

const { deepClone } = require('@/utils')
// 报价信息-数据结构
const quoteStruct = plcMap.form.quoteStruct

export default {
  name: 'RealQuoteEdit',
  components: { PriceStructView, AdjustPricePanel, PriceGrids },
  computed: {
    ...mapGetters(['userPermissions']),
    //报价方式：品类报价（1：n）；类目报价
    priceTypeMap() {
      let customPriceMap = plcMap.customPriceMap

      return customPriceMap[this.platformType] || {
        'multi': true,
        'single': true,
        'mixed': true,
        'showLast': true
      }
    }
  },
  props: {
    id: {
      type: [Number, String],
      default() {
        return null
      }
    },
    platformType: {
      type: String,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      formData: {
        id: null,
        quote_source: null,
        current_quote: quoteStruct,
        next_quote: quoteStruct,
        real_quote: quoteStruct
      },
      loadingSb: false,
      loadingNotify: false
    }
  },
  // watch: {
  //   id() {
  //     this.getInfo()
  //   }
  // },
  methods: {
    init(row) {
      // this.$notify.info(`数据加载中...${this.id}`)
      // this.getInfo()
      this.formData = { ...row }
      if (this.formData.real_quote) {
        if (this.formData.real_quote.multi) {
          //扁平化数组，如果第一项是空数组的话，则格式化 [{}]
          if (this.formData.real_quote.multi.flat(Infinity).length === 0) {
            this.formData.real_quote.multi = [{}]
          }
        }
      }
      this.$nextTick(() => {
        this.$refs['refRealQuotePanel'].show()
      })

    },
    handleReset() {
      this.formData.real_quote = { ...quoteStruct }
      this.formData.quote_source = this.formData?.quote_source ? null : null
      this.$forceUpdate()
    },
    async getInfo() {
      if (this.id) {
        let { info } = await this.$api.getPublicationItemInfo(this.id)
        this.$nextTick(() => {
          let { id, quote_source, current_quote, next_quote, real_quote } = info
          this.formData = {
            id: id,
            quote_source: quote_source,
            current_quote: current_quote,
            next_quote: next_quote,
            real_quote: real_quote || { ...quoteStruct }
          }
          this.$forceUpdate()
        })
      }
    },
    handleRichTxt() {
      const keys = Object.keys(this.formData.real_quote.mixed)
      keys.forEach((key)=>{
        this.$set(this.formData.real_quote.mixed[key], 'rich_txt', html2json(this.formData.real_quote.mixed[key].val))
      })
      // this.$set(this.formData.real_quote.mixed.sri, 'rich_txt', html2json(this.formData.real_quote.mixed.sri.val))
      // this.$set(this.formData.real_quote.mixed.mfr, 'rich_txt', html2json(this.formData.real_quote.mixed.mfr.val))
      // this.$set(this.formData.real_quote.mixed.scs, 'rich_txt', html2json(this.formData.real_quote.mixed.scs.val))
    },
    async saveQuote() {
      //保存最终价格
      this.loadingSb = true
      //富文本处理
      if (this.priceTypeMap.mixed) {
        this.handleRichTxt()
        // // 纠正历史数据问题（之后可删除该代码）
        // if (this.formData.real_quote.mixed.pfc)
        //   delete this.formData.real_quote.mixed.pfc
      }

      // 校对报价信息：不报价的项目数据清空
      if (!this.priceTypeMap.single) {
        if (this.formData.real_quote.single)
          this.formData.real_quote.single = []
      }

      if (!this.priceTypeMap.multi) {
        if (this.formData.real_quote.multi)
          this.formData.real_quote.multi = []
      }

      if (!this.priceTypeMap.mixed) {
        if (this.formData.real_quote.mixed)
          this.formData.real_quote.mixed = {}
      }

      let params = { id: this.formData.id, real_quote: this.formData.real_quote }
      if (this.formData.quote_source) {
        params['quote_source'] = this.formData.quote_source
      }
      let id = await this.$api.saveRealQuote(params)
      if (id) {
        this.$emit('saved', this.formData.real_quote)
      }
      this.loadingSb = false

    },
    selectQuote(quotes, quote_source) {
      this.formData.quote_source = quote_source
      this.formData.real_quote = deepClone(quotes)
      //重新渲染报价组件
      this.$refs['refRealQuotePanel'].show()
    },
    async notifyByDing() {
      this.loadingNotify = true
      // this.$notify.warning('定价通知：定价与你的预估价格不同，通知知晓！！！（开放中...）')
      await this.$api.handleRealQuoteNotify(this.formData.id)
      this.$notify.success('通知已发送')
      this.loadingNotify = false
    }
  }
}
</script>

<style scoped>
.box-quote {
  box-sizing: border-box;
  /*width: clamp(800px, 90%, 100%);*/
  width: 100%;
  /*border: #f2f2f2 1px dashed;*/
  padding: 2px;
}

.choice-quote {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 8px;
  width: 100%;
}

.panel-border {
  border: #f2f2f2 1px dashed;
  padding: 2px;
}

.opt-title {
  font-weight: bold;
  font-size: 14px;
  padding: 5px;
  border-radius: 10px 10px;
  margin-bottom: 8px;
}

.price-box {
  /*box-sizing: unset;*/
  width: clamp(760px, 100%, 960px);
  border: #f2f2f2 1px dashed;
  padding: 2px;
  display: inline-block
}

.quote-input {
  margin-bottom: 5px;
}

.edit-box {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.check-btn {
  margin-left: 10px;
}

.item {
  display: flex;
}

.is-label {
  flex: 3;
}

.item-val {
  flex: 3;
  margin-left: 5px;
}

.item-remark {
  flex: 5;
  margin-left: 5px;
}
</style>
