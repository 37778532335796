<template>
  <div style='display: flex;flex-direction: row;gap: 5px;justify-content: space-between; width: 99%'>
    <div style='flex: 3;' class='border-effect'>
      <!--      刊例基本信息-->
      <div class='plc-title' v-if='showTitle'>
        <span>{{ formData.year || '/' }}年{{ formData.month || '/' }}月《{{ formData.platform_name || '' }} 》刊例基本信息</span>
      </div>

      <panel-kol-info :data='formData'/>

      <panel-platform-info :data='formData'  style='min-width: 400px'/>
    </div>
    <div  style='flex: 12' class='border-effect'>
      <div class='plc-title' v-if='showTitle'>
        <span>刊例报价信息</span>
      </div>
      <!--      刊例报价信息-->
      <!--      <PriceGrids :rows='formData.real_quote' />-->
      <PriceStructView :quote-obj='formData.real_quote' :platform-type='platformType'></PriceStructView>
    </div>
    <div  style='flex: 4' class='border-effect'>
      <div class='plc-title' v-if='showTitle'>
        <span>商务案例</span>
      </div>
      <div v-html='formData.bs_case' v-if='formData.bs_case'
           style='width: 100%;;padding: 5px'></div>
      
      <div class='plc-title' v-if='showTitle'>
        <span>国际案例</span>
      </div>
      <div v-html='formData.bs_case_two' v-if='formData.bs_case_two'
           style='width: 100%;;padding: 5px'></div>
    </div>
  </div>
</template>

<script>
import PanelKolInfo from './PanelKolInfo'
import PanelPlatformInfo from './PanelPlatformInfo'
import PriceGrids from './PriceGrids'
import PanelBsCase from '@/pages/publication/compoents/PanelBsCase'
import PriceStructView from '@/pages/publication/compoents/PriceStructView'

export default {
  name: 'PanelView',
  components: { PriceStructView, PanelBsCase, PriceGrids, PanelPlatformInfo, PanelKolInfo },
  props: {
    formData: {
      type: Object,
      default() {
        return {}
      }
    },
    platformType: {
      type: String,
      default() {
        return null
      }
    },
    showTitle: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>
.plc-title {
  font-size: 18px;
  line-height: 18px;
  padding: 6px;
  border: #fe346e 1px dashed;
  background: rgba(254, 52, 110, 0.8);
  color: #f2f2f2;
  text-align: center;
}
</style>
