import { render, staticRenderFns } from "./PlatformNameSelect.vue?vue&type=template&id=5b5d7e30&scoped=true"
import script from "./PlatformNameSelect.vue?vue&type=script&lang=js"
export * from "./PlatformNameSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5d7e30",
  null
  
)

export default component.exports