<template>
  <div>
    <table-row-view :data-list='tableData' :setting='setting'></table-row-view>
  </div>
</template>

<script>
import ApeTable from '@/pages/team/components/ApeTable'
import plcMap from '@/json/plc.json'
import TableRowView from '@/pages/publication/compoents/TableRowView'
export default {
  name: 'PlcExcelTable',
  components: { TableRowView, ApeTable },
  props: {
    setting:{
      type:Object,
      default() {
        return {  }
      }
    },
    tableData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data(){
    return {
      common_cols: plcMap.common_cols,
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
